const LogoInverse = () => {
  return (
    <svg
      width="35"
      height="21"
      viewBox="0 0 35 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1610_90888)">
        <path
          d="M31.726 0.404999H3.13177C1.48619 0.404999 0.14624 1.74494 0.14624 3.39053V17.4195C0.14624 19.0651 1.48619 20.405 3.13177 20.405H31.7483C33.3939 20.405 34.7338 19.0651 34.7338 17.4195V3.41279C34.7338 1.75304 33.3838 0.404999 31.726 0.404999ZM17.2862 3.25491C17.4663 2.46147 17.861 1.78138 18.4096 1.2288H23.0063L20.4418 8.30501L20.4195 8.26048C20.2717 8.18356 20.13 8.10462 19.9944 8.02771C17.6971 6.72217 16.8207 5.32758 17.2882 3.25491H17.2862ZM3.13177 19.5812C1.94363 19.5812 0.970043 18.6076 0.970043 17.4195V3.39053C0.970043 2.20239 1.94363 1.2288 3.13177 1.2288H4.9332L12.4567 19.5812H3.13177ZM15.5172 19.5812L8.0503 1.2288H15.6953C15.25 1.89068 14.914 2.65173 14.7197 3.514C13.9384 6.96709 15.5678 9.13286 19.4338 11.0861L16.3551 19.5812H15.5151H15.5172ZM26.5019 18.555C26.4169 18.9274 26.1254 19.5812 26.1254 19.5812H18.0898L20.8344 11.916L20.9093 11.9605L22.7614 12.9058C25.6356 14.4501 27.0464 16.1463 26.5019 18.555ZM33.9121 17.4195C33.9121 18.6076 32.9385 19.5812 31.7503 19.5812H28.5907C28.7769 19.1521 28.9328 18.6866 29.0482 18.1765C29.9367 14.2417 27.5827 12.0921 23.9414 10.2259L21.8262 9.1531L24.66 1.2288H31.724C32.9263 1.2288 33.91 2.21251 33.91 3.41482V17.4195H33.9121Z"
          fill="#144733"
          style={{ fill: "var(--svg-color)" }}
        />
      </g>
      <defs>
        <clipPath id="clip0_1610_90888">
          <rect
            width="34.5876"
            height="20"
            fill="#fff"
            transform="translate(0.14624 0.404999)"
            style={{ fill: "var(--svg-color)" }}
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LogoInverse;
