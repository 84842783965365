const Cart = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.40002 8C7.40002 7.13683 7.68282 6.0033 8.39945 5.07167C9.13469 4.11586 10.3083 3.4 12 3.4C13.6942 3.4 14.8759 4.11889 15.6133 5.15126C16.223 6.00488 16.509 7.04163 16.5812 8H17C19.2091 8 21 9.79086 21 12V20H3V12C3 9.79086 4.79086 8 7 8L7.40002 8ZM8.60002 8C8.60002 7.36316 8.81723 6.4967 9.3506 5.80333C9.86536 5.13414 10.6917 4.6 12 4.6C13.3059 4.6 14.1242 5.13111 14.6368 5.84874C15.0732 6.45973 15.307 7.23624 15.3771 8L8.60002 8ZM7.40002 9.2V12L8.60002 11.9993V9.2H15.4V12L16.6 11.9995V9.2H17C18.5464 9.2 19.8 10.4536 19.8 12V18.8H4.2V12C4.2 10.4536 5.4536 9.2 7 9.2H7.40002Z"
        fill="black"
        style={{ fill: "var(--svg-color)" }}
      />
    </svg>
  );
};

export default Cart;
