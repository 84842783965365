"use client";
import useLocation from "@/lib/hooks/useLocation";
import { useLocale, useTranslations } from "next-intl";
import ReactCountryFlag from "react-country-flag";
import Button from "../Button";
import Skeleton from "../Skeleton";
import LocationPopover, {
  LOCATION_SETTINGS_MODAL
} from "./LocationSelectorPopover";
import styles from "./locationSelector.module.css";

const UI = () => {
  const t = useTranslations();
  const location = useLocation();
  const locale = useLocale().toUpperCase();
  return (
    <div className={styles.wrap}>
      <div className={styles.root}>
        {location?.location && (
          <ReactCountryFlag
            alt={location?.location}
            countryCode={location?.location}
            svg
          />
        )}
        <span>
          {location?.location ? (
            `${location?.country} (${location?.currency}) / ${locale}`
          ) : (
            <div>
              <Skeleton height="100%" />
            </div>
          )}
        </span>
      </div>

      <Button
        style={{
          opacity: "0.7"
        }}
        color="simple"
        underlined={true}
        modal={LOCATION_SETTINGS_MODAL}
        className={styles.langButton}
      >
        {t("footer.location.button.change")}
      </Button>

      <LocationPopover />
    </div>
  );
};

export default UI;
