"use client";
import clsx from "clsx";

import { Heading } from "@/components/atoms";
import Preamble from "@/components/atoms/Preamble/Preamble";
import { useCart } from "@/lib/hooks/useSelection";

import { OwnersClubStoryblok } from "@/types/storyblok-blok-types";
import { useTranslations } from "next-intl";
import CartItems from "../CartItems/CartItems";
import CartSummary from "../CartSummary/CartSummary";
import CartUpsell from "../CartUpsell/CartUpsell";
import styles from "./cart.module.css";

interface ICart {
  ownersClubBlok?: OwnersClubStoryblok;
}
const CartContent = ({ ownersClubBlok }: ICart) => {
  const t = useTranslations();
  const { items, modal, cart } = useCart();

  if (cart.summary?.totalQuantity === 0) {
    return (
      <div className={clsx(styles["cart-content"])}>
        <div className={styles["cart-empty"]}>
          <Preamble>{t("components.cartItem.cartTitle")}</Preamble>
          <Heading as="h3" styledAs="h4">
            {t("components.cartItem.emptyCart")}
          </Heading>
        </div>
      </div>
    );
  }

  return (
    <div className={clsx(styles["cart-content"])}>
      <div>
        <CartItems items={items} />
        <CartUpsell title={t("components.cartItem.upSell.title")} />
      </div>
      {cart.summary?.totalQuantity && cart.summary?.totalQuantity > 0 && (
        <CartSummary
          ownersClubBlok={ownersClubBlok}
          summary={cart.summary}
          modal={modal}
        />
      )}
    </div>
  );
};

export default CartContent;
