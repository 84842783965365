"use client";
import useLocation from "@/lib/hooks/useLocation";
import { useCart } from "@/lib/hooks/useSelection";
import {
  convertCartToGTagItem,
  eventViewCart,
} from "@/lib/utils/Analytics/Functions";
import { OwnersClubStoryblok } from "@/types/storyblok-blok-types";
import { useEffect } from "react";
import CartContent from "./CartContent";

interface ICart {
  ownersClubBlok?: OwnersClubStoryblok;
}
const Cart = ({ ownersClubBlok }: ICart) => {
  const { items, modal, cart } = useCart();
  const { currency } = useLocation();

  useEffect(() => {
    if (eventViewCart && modal.isOpen)
      eventViewCart({
        currency: currency || "",
        items: items.map((item) => convertCartToGTagItem(item)),
        value: cart.summary?.prices.totalAsNumber,
      });
  }, [modal.isOpen, items, currency, cart.summary?.prices?.totalAsNumber]);

  return <CartContent ownersClubBlok={ownersClubBlok} />;
};

export default Cart;
