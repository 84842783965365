"use client";
import { useHeaderStore } from "@/lib/bloks/statics/header/MegaHeaderBloks";
import useDevice from "@/lib/hooks/useDevice";
import useHideOnScroll from "@/lib/hooks/useHideOnScroll";
import styles from "./navbar.module.css";

const NavbarWrap = ({ children }: any) => {
  const hide = useHideOnScroll();
  const { close } = useHeaderStore();
  const { mobile } = useDevice();

  return (
    <div
      onMouseLeave={() => {
        if (mobile) return;
        close();
      }}
      className={`${styles.navWrapper} ${
        hide ? styles.hide : null
      } navigation-bar`}
    >
      {children}
    </div>
  );
};

export default NavbarWrap;
