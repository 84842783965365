"use client";

import { Button } from "@/components/atoms";
import RichText from "@/components/atoms/RichText/RichText";
import env from "@/env.mjs";
import ArrowRight from "@/icons/ArrowRight";
import clsx from "clsx";
import { useTranslations } from "next-intl";
import { usePathname } from "next/navigation";
import { useEffect, useState } from "react";
import styles from "./footer.module.css";

interface INewsletterSection {
  newsletterTitle?: string;
  newsletterText?: string;
}

const NewsletterSection = ({
  newsletterTitle,
  newsletterText
}: INewsletterSection) => {
  const [value, setValue] = useState("");
  const [state, setState] = useState("idle");
  const path = usePathname();

  const t = useTranslations();

  useEffect(() => {
    setValue("");
    setState("idle");
  }, [path]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const base = env.NEXT_PUBLIC_CENTRA_CHECKOUT_API;

    setState("fetching");
    const res = await fetch(`${base}/newsletter-subscription/${value}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        email_field: value
      })
    });
    if (res.ok) {
      setState("success");
      return;
    } else {
      setState("error");
      return;
    }
  };

  return (
    <div className={styles.newsletter}>
      <div data-layout="page">
        <div className={styles.newsletter__inner}>
          {state !== "success" && (
            <>
              {newsletterTitle && (
                <span className="preamble">{newsletterTitle}</span>
              )}
              <form onSubmit={handleSubmit} className={styles.form}>
                <div className={styles.inputWrap}>
                  <input
                    type="email"
                    name="newsletter"
                    className={`h2 ${styles.newsletter__input}`}
                    value={value}
                    placeholder="Email address"
                    required
                    onChange={(e) => setValue(e.target.value)}
                  />

                  <div className={styles.submit}>
                    <Button rounded color="transparent" outlined label="submit">
                      <ArrowRight />
                    </Button>
                  </div>
                </div>

                <label className={styles.newsletter__checkbox}>
                  <span className={styles.checkboxTrigger}>
                    <input type="checkbox" name="marketing" required />
                    <span />
                  </span>

                  {newsletterText && <RichText>{newsletterText}</RichText>}
                </label>
              </form>
            </>
          )}
        </div>

        {state === "success" && (
          <p className={clsx(styles.message, styles.success)}>
            <h2>{t("footer.newsletter.success")}</h2>
            <p>{t("footer.newsletter.successText")}</p>
          </p>
        )}

        {state === "error" && (
          <p className={styles.message}>{t("footer.newsletter.error")}</p>
        )}
      </div>
    </div>
  );
};
export default NewsletterSection;
