"use client";
import Cart from "@/components/molecules/Cart";
import Modal from "@/components/molecules/Modal";
import { useCart } from "@/lib/hooks/useSelection";
import { OwnersClubStoryblok } from "@/types/storyblok-blok-types";
import styles from "./cart.module.css";

interface ICartModal {
  ownersClubBlok?: OwnersClubStoryblok;
}
const CartModal = ({ ownersClubBlok }: ICartModal) => {
  const { cart, isLoading } = useCart();

  if (isLoading) return null;

  return (
    <Modal
      modal={"cart"}
      className={styles.cart}
      title={`Cart (${cart.summary?.totalQuantity})`}
      market={true}
      always
    >
      <Cart ownersClubBlok={ownersClubBlok} />
    </Modal>
  );
};

export default CartModal;
