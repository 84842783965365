"use client";
import { Button } from "@/components/atoms";
import styles from "@/components/molecules/Modal/modal.module.css";
import Cart from "@/icons/Cart";
import Close from "@/icons/Close";
import LogoInverse from "@/icons/LogoInverse";
import useModal from "@/lib/hooks/useModal";
import Link from "next/link";

const Toolbar = ({ modal }: any) => {
  const { closeModal } = useModal(modal, () => {});
  return (
    <div className={styles.modal__toolbar}>
      <Link href="/">
        <LogoInverse />
        <span className="sr-only">Home</span>
      </Link>

      <div>
        {/*
        <Button onClick={closeModal} rounded label="search">
          <Search />
        </Button>
        */}
        <Button onClick={closeModal} rounded label="cart">
          <Cart />
        </Button>
        <Button onClick={closeModal} rounded label="close">
          <Close />
        </Button>
      </div>
    </div>
  );
};
export default Toolbar;
