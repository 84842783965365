// @ts-nocheck
"use client";

import Link from "@/lib/bloks/atoms/Link";
import { FooterSectionStoryblok } from "@/types/storyblok-blok-types";

import { Accordion, AccordionGroup } from "@/components/atoms/Accordion";
import useDevice from "@/lib/hooks/useDevice";
import styles from "../footer.module.css";

interface IFooterNavigation {
  sections: FooterSectionStoryblok[];
}

const LinkItem = ({ blok }) => {
  if (blok.component === "divider") {
    return <div className={styles.divider} />;
  }
  return <Link blok={blok} />;
};

const RegularSection = ({ section }: { section: FooterSectionStoryblok }) => (
  <div className={styles.col}>
    {section?.name && <span className={styles.preamble}>{section?.name}</span>}
    <nav className={styles.links}>
      {section?.links?.map((linkData) => (
        <LinkItem blok={linkData} key={linkData._uid} />
      ))}
    </nav>
  </div>
);

export const FooterNavigation = ({ sections }: IFooterNavigation) => {
  const { mobile } = useDevice();
  if (mobile) {
    return (
      <AccordionGroup multiple className={styles.accordionWrapper}>
        {sections.map(({ name, _uid, links }) => (
          <Accordion
            title={name}
            key={_uid}
            styleProps={{
              accordion: styles.accordion,
              trigger: styles.trigger,
              color: "white"
            }}
          >
            <div className={styles.accordion__content}>
              {links.map((link) => {
                return <LinkItem blok={link} key={link._uid} />;
              })}
            </div>
          </Accordion>
        ))}
      </AccordionGroup>
    );
  }

  return sections.map((section) => (
    <RegularSection key={section._uid} section={section} />
  ));
};
