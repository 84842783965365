"use client";
import CartIcon from "@/icons/Cart";

import { Button } from "@/components/atoms";
import { useCart } from "@/lib/hooks/useSelection";
import styles from "./cart.module.css";

const CartToggle = () => {
  const { cart } = useCart();
  const quantity = cart.summary?.totalQuantity;

  return (
    <Button label="cart" rounded color="transparent" modal={"cart"}>
      <CartIcon />
      {quantity !== undefined && quantity > 0 && (
        <div className={styles.cart__indicator}>
          <span>{quantity}</span>
        </div>
      )}
    </Button>
  );
};

export default CartToggle;
