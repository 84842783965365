import { useEffect, useRef, useState } from "react";
import { useEventListener } from "./useEventListener";

const useHideOnScroll = () => {
  const [scrollPosition, setScrollPosition] = useState<number>(0);
  const [newScrollPosition, setNewScrollPosition] = useState<number>(0);
  const [hide, setHide] = useState(false);
  const handleScroll = () => {
    setNewScrollPosition(window.scrollY);
  };

  const prevNewScrollPosition = useRef<typeof newScrollPosition | null>(null); // guard to only run useEffect when newScrollPosition changes
  useEffect(() => {
    if (prevNewScrollPosition.current === newScrollPosition) return;
    prevNewScrollPosition.current = newScrollPosition;

    setHide(newScrollPosition > scrollPosition && newScrollPosition > 20);
    setScrollPosition(newScrollPosition);
  }, [newScrollPosition, scrollPosition]);

  useEventListener("scroll", handleScroll);

  return hide;
};

export default useHideOnScroll;
